import React from 'react'
import { NewsObject } from '../../assets/NewsObject';
import News from './News';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export default function NewsBlocks() {

  return (
    <div className='newsBlocks'>
        {NewsObject.map((value, index) => {
          return (
            <News
              key={index}
              images={value.images}
              paragraphe={value.news}
              link={value.link}
              inPageLink={value.inPageLink}
              id={value.id}
              source={value.source}
              titleExeption={value.titleExeption}
            />
          );
        })}
    </div>
  )
}
