import mawiTeam1 from './mawiTeam1.jpg';
import mawiTeam2 from './mawiTeam2.jpeg';
import mawiDnaLogo from './MawiDNA-Logo.png';
import genLogo from './logoWebsite.png';
import advisoryLogo from './partners/spireAdvisoryLogo.png';
import mawiDnaNews from './mawiDnaNews.png';
import prophaseLogo from './partners/prophase_Logo.svg';
import abubaker from './abubaker-photo.jpg';
const news1 = (
  <span>
    September 26th, 2022 – We are delighted to announce that our partner Mawi
    DNA Technologies (Mawi), a medical device company focusing on the
    development of innovative technologies for biological sample collection
    announced today that it has signed Memorandum of Understanding (MoU)
    agreements with the Moroccan Ministry of Investment.
  </span>
);
const news2 = (
  <span>
    December 23rd, 2021 – SPIRE Advisory Group is delighted to announce that
    it has entered an agreement with Mawi DNA (www.mawidna.com) to position
    the company’s DNA and RNA collection kits with international partners in
    Africa and the Middle East.
  </span>
);

const news3 = (
  <span>
    GEN and SPIRE ADVISORY GROUP Announce Strategic Partnership Agreement
    South Ct Palo Alto California~USA.
  </span>
);

const news4 = (
  <span>
    {" "}
    Today, collecting DNA seems pretty standard, but not everyone can produce
    and adequate amount of saliva and bacteria can contaminate the sample
    causing issues to arise downstream.
  </span>
);

const news5 = (
  <span>
    {" "}
    Garden City, NY, March 07, 2023 (GLOBE NEWSWIRE) -- ProPhase Labs, Inc.
    (NASDAQ: PRPH) (“ProPhase”), a growth oriented and diversified
    diagnostics, genomics and biotech company, today announced that its wholly
    owned subsidiary, Nebula Genomics, Inc. (“Nebula”), has introduced its
    lowest ever standard price of $249.00 for its direct-to-consumer.
  </span>
);

const news6 = (
  <span>
    Abubaker Seddiq Al Khoori, CFA, brings a wealth of experience and leadership to his new role. 
    As the Group CEO of Abu Dhabi Capital Group, he has demonstrated exceptional strategic acumen and financial expertise.
  </span>
)


export const NewsObject = [
  {id:1,
  images:[abubaker],
  news:news6,
  link: "",
  inPageLink: true,
  titleExeption: true,
  },
  {
    id: 3,
    images: [mawiDnaLogo],
    news: news2,
    link: "",
    inPageLink: false,
    titleExeption: false,
  },
  {
    id: 4,
    images: [genLogo, advisoryLogo],
    news: news3,
    link: "",
    inPageLink: true,
    titleExeption: false,
  },
  {
    id: 5,
    images: [mawiDnaNews],
    news: news4,
    link: "https://www.mawidna.com/blog/got-dna-simplifying-the-first-step-in-genomic-testing/",
    inPageLink: false,
    titleExeption: false,
  },
  {
    id: 6,
    images: [prophaseLogo],
    news: news5,
    link: "https://ir.prophaselabs.com/news-events/press-releases/detail/176/prophase-labs-nebula-genomics-subsidiary-introduces",
    inPageLink: false,
    titleExeption: false,
    source: 'Prophase website'
  },

]