import React from 'react'
import Header from '../Components/Header/Header2'
import Footer from '../Components/Footer/Footer'
import TeamCard from '../Components/TeamCard/TeamCard'
import ceo_Photo from "../assets/abboudBejjaniTeam.png";
import VP from "../assets/hichamJorioTeam.png";
import investor from "../assets/linaAnwarTeam.png";
import Hero from '../Components/Hero/Hero';
import teamBanner from '../assets/banners/ourteam_Banner.jpg';
import CardBoard from '../Components/CardBoard/CardBoard';
import karinePhoto from '../assets/karine-photo.png';
import attiehPhoto from '../assets/atie-photo.png';
import abubakkerPhoto from '../assets/abubaker-photo.jpg';
export default function TeamPage() {
  return (
    <>
      <Header />
      <Hero bg={teamBanner} />
      <div className="sectionContainer__team">
        <CardBoard
          headerImage={abubakkerPhoto}
          cardTitle="abubaker seddiq alkhoori"
          cardSubTitle="GEN, Chairman of the Board"
          cardInfoTitle=""
          expertise="Abubaker Seddiq Al Khoori, CFA, is a distinguished executive known for his leadership in financial and real estate sectors. As Group CEO of Abu Dhabi Capital Group, he has excelled.
    Abubaker's roles as Chairman of The National Investor (TNI) PJSC and other organizations demonstrate his strategic prowess. He has a proven track record in restructuring and financial management, raising substantial financing and delivering high-value developments. Abubaker's expertise in negotiating mergers, notably with Aldar Properties PJSC, showcases his deal-making skills. At Abu Dhabi Investment Authority, he led the private equity department to success. His educational background includes Harvard Business School programs and a CFA charter. Abubaker is committed to family and community service, evident from his board memberships."
        />
          <CardBoard
            headerImage={ceo_Photo}
            cardTitle="abboud bejjani"
            cardSubTitle="GEN, CEO & Vice Chairman"
            cardInfoTitle=""
            expertise="Abboud has an impressive and diverse background in the healthcare and pharmaceutical industry. As the CEO & Vice Chairman of GEN and a Managing Partner at Infomed, he plays key roles in these organizations. Additionally, his involvement as a board member at Julphar Pharmaceutical showcases his expertise and leadership in the industry. Abboud's certification as a consultant for renowned healthcare consultancy companies like Guidepoint and GLG highlights his valuable insights and knowledge in the field. Furthermore, his experience at multinational corporations such as Abbvie and Abbott, along with his educational achievements from institutions like Harvard Business School, INSEAD, Case Western and St Joseph University, underline his commitment to continuous learning and professional development. Abboud's extensive experience and accomplishments position him as a seasoned professional in the healthcare sector."
          />
        <CardBoard
          headerImage={attiehPhoto}
          cardTitle="atie al mouallem"
          cardSubTitle="Board Member & Chairman of SICO"
          cardInfoTitle=""
          expertise="Atie al Mouallem, a seasoned professional in strategic leadership and financial advisory, has consistently delivered tangible results.
    Holding key managerial positions at BBAC, Byblos Bank, and Barclays Bank, he drove business growth in the UAE and CCC regions. At Standard Chartered Bank, his wealth management expertise catered to high-net-worth clients. With a deep understanding of M&A dynamics, Atie managed numerous deals at Seven Emirates and Express Trading Company. Additionally, he excelled in business banking at Royal Bank of Scotland, implementing cross-sell strategies. His academic qualifications include an Executive Program in General Management from MIT, an MBA from Geneva Business School, and a Doctorate in Business Administration from Atlanta University. Atie's affiliations include roles in organizations like the Lebanese Business Council, reflecting his active involvement in professional and philanthropic activities. Proficient in Arabic, English, and French, Atie possesses strong communication skills and proficiency in various computer applications."
        />
        </div>
        <div className="sectionContainer__team">
        <CardBoard
          headerImage={karinePhoto}
          cardTitle="karine noufaily"
          cardSubTitle="Commercial Director"
          expertise="Karine Noufaily boasts over two decades of experience in the pharmaceutical industry, primarily focusing on Immunology. Starting her journey as a Medical Representative, she swiftly ascended the ranks to become a Sales Manager, Senior Brand Manager, and eventually a Brand Team Leader. Her expertise spans various functions including sales, marketing, and market access, with a particular emphasis on patient-centric approaches. Throughout her career, Karine has demonstrated a knack for rejuvenating mature brands, orchestrating successful product launches, and expanding market presence across diverse geographies like the Levant, Egypt, and Iraq. Notably, during her tenure at Abbvie, she played a pivotal role in maintaining the market dominance of Humira for over a decade through innovative life cycle management strategies. In her capacity as a Brand Team Leader, Karine effectively steered her team through the challenges posed by the entry of biosimilar competitors, implementing focused strategies to sustain her product's performance. Her leadership and strategic prowess have consistently driven business growth, while her unwavering commitment to patient well-being remains a cornerstone of her professional ethos."
          cardInfoTitle=""
        />
        <TeamCard
          teamText="Managing Partner at SPIRE Advisory,  providing strategic assistance to  enterprises;
        Lead consultant for GLG and Guidepoint Middle East Region"
          peopleText="Co-founder of biotech company Biotessia."
          experienceText="Former IBM Director of Strategic  Partnerships, specializing in AI and early  skin cancer detection."
          networkText="Co-founder of Mawitessia, a JV initiative  with Mawi DNA & Biotessia aimed for the  African market"
          img={VP}
          profile="https://www.linkedin.com/in/hichamjorio?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          name="Hicham Jorio"
          role="VP Business Development"
        />
        <TeamCard
          teamText="Co-founder of NassihaCom, 1st  administration & public policy advisory  ﬁrm in Morocco"
          networkText="BA from McGill University in Poli Sci &  MSc In Business Management from  Boston University."
          peopleText="Investor Relations Director at Mawitessia"
          experienceText="Former fraud analyst expert at CIBC in  Canada & Former strategy consultant for  Azenta Life Sciences, Silicon Valley Bank & U.S Bank."
          img={investor}
          profile="https://www.linkedin.com/in/lina-anwar-8b4543171?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app"
          name="Lina Anwar"
          role="Investor Relations Director"
        />
      </div>
      <Footer />
    </>
  )
}
