import React from 'react'
import gen_img from '../../assets/logoWebsite.png';
export default function CardBoard({headerImage,cardTitle,cardSubTitle,cardInfoTitle,expertise}) {
  return (
    <div className='card-board'>
        <img src={gen_img} alt='gen-logo' className='card-board__Logo'/>
        <div className='card-board__header'>
            <img src={headerImage} alt='card-img' className='card-board__headerImage'/>
            <div className='card-board__infos'>
                <h4 className='card-board__headerTitle'>{cardTitle}</h4>
                <h5 className='card-board__headerSubTitle'>{cardSubTitle}</h5>
                <h5 className='card-board__InfoTitle'>{cardInfoTitle}</h5>
            </div>
        </div>
        <p className='card-board__expertise'>{expertise}</p>
    </div>
  )
}
